.footerMenu1 li:not(:first-child) {
  @media (max-width: theme('screens.xl')) {
    position: relative;
    padding-left: 10px;

    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 0px;
      transform: translate(-50%, -50%);
      top: 50%;
      width: 1px;
      height: 50%;
      background-color: currentColor;
    }
  }
}
