.accordion__label {
  display: grid;
  grid-template:
    'i t a'
    'c c c'
    / 23px auto auto;

  @media (min-width: theme('screens.xl')) {
    grid-template:
      'i t a'
      'i c a'
      / 50px auto auto;
  }

  &-content {
    grid-area: c;
  }
  &-arrow {
    grid-area: a;
    justify-self: flex-end;
  }
  &-title {
    grid-area: t;
  }
  &-icon {
    grid-area: i;
  }

  &-divider {
    width: calc(100% + var(--container-px));
    @media (min-width: theme('screens.xl')) {
      width: 100%;
    }
  }
}

input:checked.accordion-input ~ .accordion-content {
  max-height: 100vh;
  overflow-y: auto;
}

input:checked.accordion-input + label .icon-arrow {
  opacity: 1;
  transform: rotate(180deg);
  color: black;
}

.accordion-content {
  max-height: 0;
  transition: 0.2s;
  overflow-y: hidden;

  @media (max-width: theme('screens.xl')) {
    @apply mx-full;
  }
}
