:root {
  --zindexOvery: 50;
  --zindexMobile: 30;
  --zindexSliderMenu: 60;
  --zindexFloatBanner: 500;
  --header-g-height: 100px;

  @media screen and (max-width: 980px) {
    --header-g-height: 64px;
  }
}




.background-overlay {
  background: #fff;
  height: var(--header-g-height);
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;

}


header {
  li {
    position: relative;
    .lang {
      position: relative;
      display: flex;

      justify-content: center;
      align-items: center;
      border: 1px solid #222;

      box-sizing: border-box;
      font-family: 'Nunito Sans';
      font-size: 11px;
      &.active {
        .lang-menu {
          display: flex;
        }
      }
      #lang-txt {
        position: relative;
        top: 1px;
      }
      .lang-menu {
        position: absolute;
        top: calc(100% + 5px);
        right: 0;
        display: none;
        flex-direction: column;
        width: 40px;
        overflow: hidden;
        border-radius: 5px;
        li {
          font-size: 12px;
          height: 24px;
          line-height: 24px;
          border-bottom: 1px solid #f1f1f1;
          padding: 0 10px;
          background: #222;
          box-sizing: border-box;
          &:hover {
            background: #f9f9f9;
          }
          &:active {
            background: #f1f1f1;
          }
        }
      }
    }
  }
  .gnb-toggle-btn {
    position: absolute;
    z-index: 10;
    top: 24px;
    right: 18px;
    display: inline-block;
    width: 33px;
    height: 18px;
    &.close {
      background: #efefef;
      padding: 20px;
      text-align: center;
      border-radius:100%;
      top: 24px;
      right: 20px;
      &:before,
      &:after {
        position: absolute;
        display: block;
        width: 50%;
        height: 2px;
        top: 50%;
        left:50%;
        margin-left: -9px;
        border-radius: 1.5px;
        background: #fff;
      }
      &:before {
        transform: translateY(-50%) rotate(-45deg);
      }
      &:after {
        transform: translateY(-50%) rotate(45deg);
      }
    }
    &:before,
    &:after {
      position: absolute;
      display: block;
      height: 3px;
      right: 0;
      border-radius: 1px;
    }
    &:before {
      content: '';
      width: 100%;
      top: 0;
    }
    &:after {
      content: '';
      width: 33px;
      bottom: -1px;
    }
    span {
      position: absolute;
      display: block;
      height: 3px;
      border-radius: 1px;
      width: 33px;
      top: calc(50% - 1px);
      right: 0;
    }
  }
  .logo {
    img {
      height: 100%;
    }
  }





  #mobile-menu-wrap {
    position: fixed;
    width: 100%;
    height: var(--header-g-height);
    padding: 10px 10px;
    box-sizing: border-box;
    z-index: var(--zindexMobile);
    display: none;
    z-index: 2;
    background: transparent;
    display: block;

    & > .gnb-toggle-btn {
      span,
      &:after,
      &:before {
        background: #000;
      }
    }

    .overlay {
      position: fixed;
      width: 100vw;
      height: 100vh;
      top: 0;
      left: 0;
      z-index: var(--zindexOvery, 50);
      display: none;

      background-color: rgba(0, 0, 0, 0.6);

      &.on {
        display: inline-block;
      }
    }
    .logo {
      position: absolute;
      top: 14px;
      left: 10px;
      img {
        height: 40px;
      }
    }
    .m-menu {
      position: fixed;
      width: 100%;
      height: 100%;
      padding: 0 30px;
      top: 0;
      right: -100%;
      box-shadow: 0 15px 30px 0 rgba(28, 0, 60, 0.35);
      z-index: var(--zindexFloatBanner);
      transition: 0.3s;
      text-align: center;
      background-color: #fff;

      &.on {
        right: 0;
      }
      #m-gnb {
        height: 80%;
        width: 100%;
        overflow: auto;
        margin-top: 65px;
        // 대분류 소분류 공통

        ul {



          li {

            & + li {

            }
            .menu-wrap{
              padding: 20px;

              border-bottom: 1px solid #efefef;
            }

            .menu-wrap {
              & > a {
                @apply font-TmoneyRoundWind;

                color: #3c3c3c;
                font-size: 22px;
                font-weight: bold;
                line-height: 1;
                transition: 0.3s;
              }
              .submenu {
                margin: 20px 0 40px;
                display: none;


                .items {

                  &:hover,
                  &:active {
                    a {
                      color: #bc9dff;

                    }


                  }
                  & + .items {
                    margin-top: 10px;
                  }
                  a {
                    padding:0 10px;
                    font-size: 18px;
                    opacity: 1;
                    transition: 0.3s;
                    letter-spacing: -1.5px;
                    text-shadow: 1px 1px rgba(0, 0, 0, 0.2);


                  }

                }
              }
            } //menu-wrap
            &.active {
              .menu-wrap {
                & > a {
                  color: #bc9dff;
                  font-weight: bold;
                }
                .submenu {
                  display: block;
                }

                .active-icon {
                  visibility: visible !important;
                }
              }
            } //active
          } //li
        }
      }
      .info-group {
        width: 100%;
        display: flex;
        align-items: center;
        height: 60px;
        padding: 0 ;

        ul, .select-wrapper {
          width: 100% !important;
        }

        .select-list-box {
          bottom: 36px !important;
        }
        a,
        div {
          font-size: 14px;
          font-weight: bold;
          letter-spacing: -0.28px;
        }
        .lang {
          & > * {
            float: left;
          }
          select {
            margin-left: 5px;
            appearance: none;
            outline: none;
            background-image: none;
            border: 0;
            background-color: transparent;
            background-image: url('../img/input-select-arrow-w.png');
            background-position: right center;
            background-repeat: no-repeat;
            padding-right: 20px;
            option {
            }
          }
        }
      }
      .account-info {
        position: absolute;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 80px;
        left: 0;
        bottom: 0;
        padding: 0 20px 0 30px;
        mix-blend-mode: lighten;

        span {
          font-size: 18px;
          font-weight: bold;
          letter-spacing: -0.36px;
        }
        a {
          img {
            width: 32px;
          }
        }
      }
    }
  }
  #dt-menu-wrap {
    position: fixed;
    width: 100%;
    height: var(--header-g-height);
    padding: 0px 25px;
    box-sizing: border-box;
    background-color: #fff;
    &.active {
      height: 310px;

      #gnb ul li .menu-wrap .submenu {
        opacity: 1;
        top: 64px;
      }
    }
    display: flex;
    z-index: var(--zindexSliderMenu);
    justify-content: space-between;
    transition: 0.5s;
    .dt-menu {

      max-width: 1400px;
      width: 100%;
      height: var(--header-g-height);
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
    }
    .gnb-toggle-btn {
      display: none;
    }
    .logo,
    #gnb,
    .right-menu {
      display: flex;
    }
    .logo {
      align-items: center;
      img {
        height: 65px;
      }
    }
    #gnb {
      & > ul {
        // 대분류 메뉴
        li {
          margin-right: 50px;
          &:last-child {
            margin-right: 0px;
          }


        }
      }


      & > ul.en_li {
        // 대분류 메뉴
        li {
          text-align: center;
          margin-right: 40px;
          &:last-child {
            margin-right: 0px;
          }
          @media screen and (max-width: 1071px) {
            margin-left: 5px;
            margin-right: 12px;
          }

        }
      }

      ul {
        // 대분류 소분류 공통
        display: flex;
        justify-content: space-between;
        align-items: center;
        li {
          position: relative;
          display: flex;
          align-items: center;
          height: 100%;
          .menu-wrap {
            position: relative;
            & > a {
              @apply text-black font-TmoneyRoundWind;
              font-size: 22px;
              font-weight: bold;
              line-height: 1;
              transition: 0.3s;
              color:#c5c5c5;
            }
            .submenu {
              position: absolute;
              width: 100%;
              top: 0;
              opacity: 0;
              transition: 0.3s;
              .items {
                &:hover {
                  a {
                    color: #bc83ff;
                    opacity: 1;
                  }
                }
                & + .items {
                  margin-top: 20px;
                }
                a {
                  font-size: 18px;
                  opacity: 1;
                  transition: 0.3s;
                  letter-spacing: -1.5px;
                }
              }
            }
          }
          &.active ,
          &:hover {
            .menu-wrap {

              & > a {
                color: #bc82fe;
                font-weight: bold;
                // background: linear-gradient(to right, #da72ef 1%, #bc82fe 101%);
                // -webkit-background-clip: text;
                // -webkit-text-fill-color: transparent;
              }
            }
          }
        }
      }
    }
    .right-menu {
      & > ul {
        display: flex;
        justify-content: space-between;
        align-items: center;

        & > li {

          margin-right: 15px;

          &:last-child {
            margin-right: 0;
          }

          a {
            line-height: 1;

            img.logout {
              width: 95%;
              height: 95%;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 980px) {
  header {
    #dt-menu-wrap {
      display: none;
    }
  }
  header {
    #mobile-menu-wrap {
      display: flex;
      justify-content: space-between;
    }
  }
  .main-banner {
    #main-banner-swiper {
      .swiper-wrapper {
        .swiper-slide {
          .slider-text {
            img {
              width: 20%;
              height: 20%;
              margin-bottom: 10px;
            }
            p {
              &.title {
                font-size: 30px;
                font-weight: 800;
              }
              font-size: 14px;
            }
          }
        }
      }
    }
  }
} //break point 980px


.event .select-list-box {
  position: absolute;
  bottom: 36px;
}